import axios from 'axios';
import { showTabs, selectTab } from '../common/tab/tabActions';
import consts from '../consts';
import { setErroAPI, setSuccess } from '../common/toast/toast';
import { reset as resetForm, initialize } from 'redux-form';
import DateFormat from '../common/dateFormat/DateFormat';
import FormatUtils from '../common/formatUtils/FormatUtils';

export function setModoTela(modo, registro = {}) {
    return {
        type: 'CHAMADA_AGENDA_MODO_TELA',
        payload: {
			modo: modo,
			registro: registro
		}
    };
}

export function setAguardando(aguardando) {
    return {
        type: 'CHAMADA_AGENDA_SET_AGUARDANDO',
        payload: aguardando
    };
}

export function initForm(registro = {}) {
    return [
        initialize('chamadaAgendaForm', registro)
    ];
}

export function getLista() {
	const request = axios.get(`${consts.API_URL}/chamadaAgenda`);
	return {
		type: 'CHAMADA_AGENDA_LISTADO',
		payload: request
	};
}

export function salvar(registro) {
    return (dispatch, getState) => {
		dispatch(setAguardando(true));
		if (!registro.id) {
	        axios.post(`${consts.API_URL}/chamadaAgenda`, {
				...registro,
				colaboradores: FormatUtils.formatarValorBanco(registro.colaboradores, 0),
				jovem_aprendizes: FormatUtils.formatarValorBanco(registro.jovem_aprendizes, 0),
				id_usuario_inclusao: getState().auth.usuario.id,
				id_usuario_alteracao: getState().auth.usuario.id
			}).then(resp => {
				setSuccess('Operação Realizada com sucesso.');
				dispatch(getLista());
				dispatch(setModoTela('lista'));
				dispatch(setAguardando(false));
			}).catch(e => {
				setErroAPI(e);
				dispatch(setAguardando(false));
			});
		} else {
			axios.put(`${consts.API_URL}/chamadaAgenda`, {
				...registro,
				colaboradores: FormatUtils.formatarValorBanco(registro.colaboradores, 0),
				jovem_aprendizes: FormatUtils.formatarValorBanco(registro.jovem_aprendizes, 0),
				id_usuario_alteracao: getState().auth.usuario.id
			}).then(resp => {
				setSuccess('Operação Realizada com sucesso.');
				dispatch(getLista());
				dispatch(setModoTela('lista'));
				dispatch(setAguardando(false));
			}).catch(e => {
				setErroAPI(e);
				dispatch(setAguardando(false));
			});
		}
    };
}

export function excluir(registro) {
    return (dispatch, getState) => {
        axios.delete(`${consts.API_URL}/chamadaAgenda?id=${registro.id}&id_usuario_alteracao=${getState().auth.usuario.id}`)
		.then(resp => {
			setSuccess('Operação Realizada com sucesso.');
			dispatch(getLista());
			dispatch(setModoTela('lista'));
		}).catch(e => {
			setErroAPI(e);
		});
    };
}

export function getListaCliente() {
    const request = axios.get(`${consts.API_URL}/cliente/listarSelect`);
    return {
        type: 'CHAMADA_AGENDA_CLIENTE_SELECT_LISTADO',
        payload: request
    };
}

export function getListaUnidade() {
    const request = axios.get(`${consts.API_URL}/unidade/listarSelect`);
    return {
        type: 'CHAMADA_AGENDA_UNIDADE_SELECT_LISTADO',
        payload: request
    };
}

export function getListaSetor() {
    const request = axios.get(`${consts.API_URL}/setor/listarSelect`);
    return {
        type: 'CHAMADA_AGENDA_SETOR_SELECT_LISTADO',
        payload: request
    };
}