import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { reduxForm, Field, formValueSelector, getFormValues } from 'redux-form';

import Content from '../common/template/content';
import ContentCard from '../common/template/contentCard';
import ContentCardHeader from '../common/template/contentCardHeader';
import ContentCardBody from '../common/template/contentCardBody';
import ContentCardFooter from '../common/template/contentCardFooter';
import Row from '../common/layout/row';
import Grid from '../common/layout/grid';
import Button from '../common/button/button';
import Form from '../common/form/form';
import LabelAndCheckbox from '../common/form/labelAndCheckbox';
import LabelAndInput from '../common/form/labelAndInput';
import LabelAndInputNumber from '../common/form/labelAndInputNumber';
import LabelAndInputMask from '../common/form/labelAndInputMask';
import LabelAndInputDateTime from '../common/form/labelAndInputDateTime';
import Select from '../common/form/select';
import SelectDefault from '../common/form/selectDefault';
import Table from '../common/table/table';
import THead from '../common/table/tHead';
import Tr from '../common/table/tr';
import Th from '../common/table/th';
import TBody from '../common/table/tBody';
import Td from '../common/table/td';

import {
	setModoTela, initForm, getListaItem, salvarItem, excluirItem
} from './chamadaActions';
import ClienteUtils from '../common/utils/ClienteUtils';
import ButtonTable from '../common/table/buttonTable';
import InputAssinatura from '../common/form/inputAssinatura';

class ChamadaForm extends Component {

	state = {

	}

    componentWillMount() {

		this.props.getListaItem(this.props.formularioValues);

    }

    render() {

		let readOnly = this.props.excluir ? 'readOnly' : '';

		return (
			<ContentCard>
				{this.modalAssinatura()}

				<Form event={this.props.handleSubmit}>
					<ContentCardBody>
						<Row>
							
							<Field
								name='data_chamada'
								component={LabelAndInputMask}
								label='Data da Chamada' placeholder='Informe a data'
								cols='12 4 4 4'
								readOnly={readOnly}
								mask='99/99/9999' />
								
							<Field
								name='feriado'
								component={LabelAndCheckbox}
								label='Feriado' placeholder='Informe'
								cols='12 4 4 4'
								readOnly={readOnly} />
								
							<Field
								name='reuniao'
								component={LabelAndCheckbox}
								label='Reunião' placeholder='Informe'
								cols='12 4 4 4'
								readOnly={readOnly} />

							<Field
								name='id_cliente'
								component={Select}
								options={this.props.listaCliente}
								label='Cliente'
								cols='12 4 4 4'
								placeholder='Selecione o cliente'
								readOnly={this.props.formularioValues.id ? 'readOnly' : readOnly} />
								
							<Field
								name='id_unidade'
								component={Select}
								options={this.props.listaUnidade.filter(item => item.id_cliente == this.props.formularioValues.id_cliente)}
								label='Unidade'
								cols='12 4 4 4'
								placeholder='Selecione o unidade'
								readOnly={this.props.formularioValues.id ? 'readOnly' : readOnly} />
								
							<Field
								name='id_setor'
								component={Select}
								options={this.props.listaSetor.filter(item => item.id_unidade == this.props.formularioValues.id_unidade)}
								label='Setor'
								cols='12 4 4 4'
								placeholder='Selecione o setor'
								readOnly={this.props.formularioValues.id ? 'readOnly' : readOnly} />

						</Row>

						<Row>
							<Grid cols='6 6 4 3'>
								<Button
									text='Abrir Assinatura'
									type={'secondary'}
									icon={'fa fa-chevron-left'}
									event={() => this.setState({ ...this.state, modalAssinatura: true })} />
							</Grid>
							{this.props.formularioValues.assinatura &&
							<Grid cols='12 12 12 12'>
								<img
									style={{
										border: '2px solid #000',
										backgroundColor: '#ffffff',
										width: window.innerWidth < 500 ? window.innerWidth - 35 : 500,
										height: 83,
									}}
									src={this.props.formularioValues.assinatura}/>
							</Grid>}
																
						</Row>

						{(this.props.formularioValues.id && !this.props.excluir) &&
						<Row>
							<Grid cols='12 12 12 12'>
								<p style={{ padding: 1, margin: 0 }}><b>Presente</b> - Participou da Ginástica Laboral.</p>
								<p style={{ padding: 1, margin: 0 }}><b>Ausente</b> - Licença maternidade, laudo, atestado, férias, folga, visita a clientes, reunião, fora do setor, atendendo cliente, no telefone, em áudio, etc.</p>
								<p style={{ padding: 1, margin: 0 }}><b>Falta</b> - Poderia participar da ginástica laboral, mas não participou.</p>
							</Grid>
							<Grid cols='12 12 12 12'>

								<Table responsive>
									<THead>
										<Tr>
											<Th colspan={2}>Colaboradores</Th>
											{this.props.sessao.id_perfil == 1 &&
											<Th></Th>}
										</Tr>
									</THead>
									<TBody>
										{this.props.listaItem.map(item => (
											<Tr key={item.id} >
												<Td alignCenter width={120}>
													<select 
														style={{ marginTop: 4, marginBottom: 4, height: 30 }}
														value={item.id_presenca}
														onChange={data => {
															this.props.salvarItem({
																...item,
																id_presenca: data.target.value
															})
														}}>
														<option value='0'>Selecione</option>
														<option value='1'>Presente</option>
														<option value='2'>Falta</option>
														<option value='3'>Ausente</option>
													</select>
												</Td>
												<Td>{item.nome_usuario}</Td>
												{this.props.sessao.id_perfil == 1 &&
												<Td alignRight>
													<ButtonTable
														type={'danger'}
														icon={'fas fa-trash-alt'}
														visible={true}
														event={() => {
															this.props.excluirItem({
																...item
															});
														}} />
												</Td>}
											</Tr>
										))}
									</TBody>
								</Table>
							</Grid>
						</Row>}

						{(this.props.formularioValues.id && !this.props.excluir) &&
						<Row alignCenter>
							<Grid cols='12 12 12 8'>
								<div style={{ display: 'flex', flexDirection: 'row', marginBottom: 40 }}>
									
									<div className='form-group' style={{ width: '80%' }}>
										<label htmlFor={'colaborador'}>Colaborador</label>
										<select
											className='form-control'
											value={this.state.id_usuario_novo}
											onChange={data => {
												this.setState({
													...this.state,
													id_usuario_novo: data.target.value
												});
											}} >
											<option value={0}>Selecione</option>
											{this.props.listaUsuario.filter(item => {
												if (!this.props.formularioValues.id_cliente || item.id_cliente != this.props.formularioValues.id_cliente) {
													return false;
												}

												if (this.props.listaItem.filter(chamadaItem => chamadaItem.id_usuario == item.id).length) {
													return false;
												}

												return true;
											}).map(opt => {
												return (
													<option
														key={opt.id}
														value={opt.id}>{opt.valor}</option>
												);
											})}
										</select>
									</div>

									<div style={{ width: '20%', height: 20, marginTop: 28 }}>
										<Button
											text='Adicionar'
											type={'primary'}
											icon={'fa fa-chevron-left'}
											disabled={!this.state.id_usuario_novo}
											event={() => {
												this.props.salvarItem({
													id_chamada: this.props.formularioValues.id,
													id_usuario: this.state.id_usuario_novo,
													id_presenca: 1
												});
												this.setState({
													...this.state,
													id_usuario_novo: 0
												});
											}} />
									</div>
								</div>
							</Grid>
						</Row>}

					</ContentCardBody>
					<ContentCardFooter>
						<Row alignCenter>
							<Grid cols='6 6 4 3'>
								{this.props.excluir ? (
									<Button
										text='Excluir'
										submit type={'danger'}
										icon={'fa fa-trash'} />
								) : (
								<Button
									text='Salvar'
									submit
									type={'success'}
									icon={'fa fa-check'} />
								)}
							</Grid>
							<Grid cols='6 6 4 3'>
								<Button
									text='Voltar'
									type={'warning'}
									icon={'fa fa-chevron-left'}
									event={() => this.props.setModoTela('lista')} />
							</Grid>
						</Row>
					</ContentCardFooter>
				</Form>
			</ContentCard>
		);
    }

	modalAssinatura() {

		if (!this.state.modalAssinatura) {
			return null;
		}

        return (
			<div class='modal fade show' id='modal-xl' style={{ display: 'block' }} aria-modal='true'>
				<div class='modal-dialog modal-xl'>
					<div class='modal-content'>
						<div class='modal-header'>
							<h4 class='modal-title'>Aceite</h4>
							<button type='button' class='close' data-dismiss='modal' aria-label='Close' onClick={() => this.setState({ ...this.state, modalAssinatura: false })}>
								<span aria-hidden='true'>×</span>
							</button>
						</div>
						<div style={{ maxHeight: window.innerHeight - 200, overflowY: 'scroll' }}>
						<div style={{ paddingLeft: 8 }}>
								<InputAssinatura
									label={`Assinatura`}
									value={this.props.formularioValues.assinatura}
									onChange={(assinatura) => {
										this.props.initForm({
											...this.props.formularioValues,
											assinatura: assinatura
										});
									}} />
							</div>
						</div>
						<div class='modal-footer justify-content-between'>
							<button type='button' class='btn btn-default' data-dismiss='modal' style={{ visibility: 'hidden' }}>Close</button>
							<button type='button' class='btn btn-primary'
								onClick={() => this.setState({ ...this.state, modalAssinatura: false })}>Fechar</button>
						</div>
					</div>
				</div>
			</div>
        );
    }


}

ChamadaForm = reduxForm({form: 'chamadaForm', destroyOnUnmount: false})(ChamadaForm);
const mapStateToProps = state => ({
	sessao: state.auth.usuario,
	formularioValues: getFormValues('chamadaForm')(state),
	registro: state.chamada.registro,
	listaItem: state.chamada.listaItem,
	listaCliente: ClienteUtils.filtrarLista(state.chamada.listaCliente.map(item => ({ ...item, id_cliente: item.id })), state.auth.usuario),
	listaUsuario: state.usuario.listaSelect,
	listaUnidade: state.chamada.listaUnidade,
	listaSetor: state.chamada.listaSetor,
	listaPresenca: state.chamada.listaPresenca
});
const mapDispatchToProps = dispatch => bindActionCreators({
	setModoTela, initForm, getListaItem, salvarItem, excluirItem
}, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(ChamadaForm);
