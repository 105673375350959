import React, { useRef, useState, useEffect } from 'react';

const InputAssinatura = (props) => {
  const canvasRef = useRef(null);
  const [isAssinando, setIsAssinando] = useState(false);

  useEffect(() => {
    const canvas = canvasRef.current;
    const ctx = canvas.getContext('2d');
    ctx.lineWidth = 2;
    ctx.lineCap = 'round';
    ctx.strokeStyle = 'black';
  }, []);

  const iniciarAssinatura = (x, y) => {
    const ctx = canvasRef.current.getContext('2d');
    ctx.beginPath();
    ctx.moveTo(x, y);
    setIsAssinando(true);
  };

  const assinatura = (x, y) => {
    if (!isAssinando) return;
    const ctx = canvasRef.current.getContext('2d');
    ctx.lineTo(x, y);
    ctx.stroke();
  };

  const finalizarAssinatura = () => {
    const ctx = canvasRef.current.getContext('2d');
    ctx.closePath();
    setIsAssinando(false);
  };

  const handleMouseDown = (e) => {
    iniciarAssinatura(e.nativeEvent.offsetX, e.nativeEvent.offsetY);
  };

  const handleMouseMove = (e) => {
    if (isAssinando) {
      assinatura(e.nativeEvent.offsetX, e.nativeEvent.offsetY);
    }
  };

  const handleTouchStart = (e) => {
    const rect = canvasRef.current.getBoundingClientRect();
    const x = e.touches[0].clientX - rect.left;
    const y = e.touches[0].clientY - rect.top;
    iniciarAssinatura(x, y);
  };

  const handleTouchMove = (e) => {
    e.preventDefault(); // Evita o scroll enquanto desenha
    const rect = canvasRef.current.getBoundingClientRect();
    const x = e.touches[0].clientX - rect.left;
    const y = e.touches[0].clientY - rect.top;
    assinatura(x, y);
  };

  const handleTouchEnd = () => {
    finalizarAssinatura();
  };

  const limparAssinatura = () => {
    const canvas = canvasRef.current;
    const ctx = canvas.getContext('2d');
    ctx.clearRect(0, 0, canvas.width, canvas.height);
    props.onChange(null);
  };

  const salvarAssinatura = () => {
    const canvas = canvasRef.current;
    const dataURL = canvas.toDataURL(); // Converter para base64
    props.onChange(dataURL);
  };

  return (
    <div
      className="form-group"
      style={{
        display: 'flex',
        flexDirection: 'column',
        width: window.innerWidth < 500 ? window.innerWidth - 45 : 500,
      }}
    >
      {props.label && <label>{props.label}</label>}

      <canvas
        ref={canvasRef}
        width={window.innerWidth < 500 ? window.innerWidth - 45 : 500}
        height={80}
        style={{
          border: '2px solid #000',
          backgroundColor: '#ffffff',
          display: props.value ? 'none' : null,
        }}
        onMouseDown={handleMouseDown}
        onMouseMove={handleMouseMove}
        onMouseUp={finalizarAssinatura}
        onMouseLeave={finalizarAssinatura}
        onTouchStart={handleTouchStart}
        onTouchMove={handleTouchMove}
        onTouchEnd={handleTouchEnd}
      />

      {props.value && (
        <img
          style={{
            border: '2px solid #000',
            backgroundColor: '#ffffff',
            width: window.innerWidth < 500 ? window.innerWidth - 45 : 500,
            height: 83,
          }}
          src={props.value}
        />
      )}

      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          paddingTop: 2,
        }}
      >
        <button
          type="button"
          className="btn btn-block btn-success btn-xs"
          onClick={salvarAssinatura}
          style={{
            marginTop: 0,
            marginLeft: 0,
            marginBottom: 0,
            marginRight: 1,
          }}
          disabled={props.value}
        >
          Ok
        </button>
        <button
          type="button"
          className="btn btn-block btn-danger btn-xs"
          onClick={limparAssinatura}
          style={{
            marginTop: 0,
            marginLeft: 1,
            marginBottom: 0,
            marginRight: 0,
          }}
        >
          Limpar
        </button>
      </div>
    </div>
  );
};

export default InputAssinatura;
