const INITIAL_STATE = {
	registro: {},
	modoTela: 'lista',
	aguardando: false,
	lista: [],
	listaItem: [],
	listaCliente: [],
	listaUnidade: [],
	listaSetor: [],
	listaDiaSemana: [
		{ id: 1, valor: 'Domingo' }, { id: 2, valor: 'Segunda-feira' }, { id: 3, valor: 'Terça-feira' }, 
		{ id: 4, valor: 'Quarta-feira' }, { id: 5, valor: 'Quinta-feira' }, { id: 6, valor: 'Sexta-feira' }, 
		{ id: 7, valor: 'Sábado' }
	]
};

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
		case 'CHAMADA_AGENDA_MODO_TELA':
            return { ...state,
				modoTela: action.payload.modo,
				registro: action.payload.registro
			};

		case 'CHAMADA_AGENDA_SET_AGUARDANDO':
			return { ...state,
				aguardando: action.payload
			};

        case 'CHAMADA_AGENDA_LISTADO':
            return {
				...state,
				lista: action.payload.data
			};

		case 'CHAMADA_AGENDA_ITEM_LISTADO':
            return {
				...state,
				listaItem: action.payload.data
			};

		case 'CHAMADA_AGENDA_CLIENTE_SELECT_LISTADO':
            return {
				...state,
				listaCliente: action.payload.data
			};

		case 'CHAMADA_AGENDA_UNIDADE_SELECT_LISTADO':
            return {
				...state,
				listaUnidade: action.payload.data
			};

		case 'CHAMADA_AGENDA_SETOR_SELECT_LISTADO':
            return {
				...state,
				listaSetor: action.payload.data
			};

        default:
            return state;
    }
}
